@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-Light.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-Bold.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-Book.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-Book.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-Medium.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-Light.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-Bold.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-BoldItalic.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-Black.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-Black.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-BookItalic.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-BookItalic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-LightItalic.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-LightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-BookItalic.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-BookItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-UltraItalic.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-UltraItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-Medium.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-MediumItalic.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-MediumItalic.woff") format("woff");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-XLight.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-XLight.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-ThinItalic.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-ThinItalic.woff") format("woff");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-XLightItalic.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-XLightItalic.woff") format("woff");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("./assets/fonts/gotham/Gotham-Thin.woff2") format("woff2"), url("./assets/fonts/gotham/Gotham-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('./assets/fonts/avenir/AvenirLTStd-Black.woff') format('woff2'), url('./assets/fonts/avenir/AvenirLTStd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('./assets/fonts/avenir/AvenirLTStd-Book.woff') format('woff2'), url('./assets/fonts/avenir/AvenirLTStd-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('./assets/fonts/avenir/AvenirLTStd-Roman.woff') format('woff2'), url('./assets/fonts/avenir/AvenirLTStd-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html {
    font-family: "Gotham";
}

input {
    text-align: inherit;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}